.homeDashboard-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .homeDashboard-screen-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
    .homeDashboard-screen-title {
      text-align: center;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
    }
  }
  .homeDashboard-main-grid {
    width: 100%;
    margin: 0px;
    .homeDashboard-grid-item {
      padding: 1%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover {
        transform: scale(1.01);
      }
      .homeDashboard-data-container {
        padding: 0px;
        width: 100%;
        /* background-color: rgba(240, 228, 130, 0.65); */
        background-color: rgb(239, 239, 239, 0.7);
      }
    }
  }
}
