.barbershops-edit-title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1%;
  .barbershops-edit-screen-title {
    text-align: center;
    padding-bottom: 1%;
    text-align: center;
    font-size: 2.05em;
    letter-spacing: 0.03em;
    text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
  }
}
