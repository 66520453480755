.data-grid-container {
  height: 67.3vh;
  max-height: 67.3vh;
  width: 100%;
  .data-table {
    .column-title {
      background-color: #efb300dd;
      text-align: center;
      padding: 0%;
    }
    .MuiDataGrid-row:hover {
      background-color: lightgray;
    }
    .MuiDataGrid-cell {
      justify-content: center;
    }
  }
}

.custom-toolbar-container {
  .grid-toolbar-container {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .toolbar-items-container {
      width: 100%;
      padding: 1%;
      .toolbar-item-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .btt1 {
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75em;
          width: 100%;
          text-transform: uppercase;
          letter-spacing: 2.5px;
          font-weight: 500;
          color: rgb(255, 255, 255);
          background-color: rgb(28, 160, 72);
          border: none;
          border-radius: 45px;
          box-shadow: 0px 8px 15px rgba(28, 160, 72);
          transition: all 0.3s ease 0s;
          cursor: pointer;
          outline: none;
          .btt1:hover {
            background-color: #23c483;
            box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
            color: #fff;
            transform: translateY(-7px);
          }

          .btt1:active {
            transform: translateY(-1px);
          }
        }
        .category-link {
          text-decoration: none;
          max-width: 100%;
        }
      }
      .toolbar-item-search {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .search-bar {
          width: 100%;
        }
      }
    }
  }
}
