.registerForm-main-container {
  width: 100%;
  .register-inputs-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .register-title-container {
      margin-top: 1.2%;
      padding: 0px;
      .register-page-title {
        letter-spacing: 0.11rem;
        margin: 0px;
        font-size: 2em;
      }
    }

    .register-grid-container {
      padding-bottom: 3%;
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      .register-grid-inputs {
        width: 80%;
        border-radius: 15px;
        margin: 0%;
        display: flex;
        justify-content: center;
        .register-grid-item {
          margin: 1%;
          width: 85%;
        }
      }
    }
  }
  .register-buttons-container {
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 1%;
    width: 100%;
    .register-buttons-stack {
      justify-content: center;
    }
    .back-btn-container {
      margin-top: 3%;
      .text-back-btn {
        color: black;
        font-size: 0.85em;
      }
    }
  }
}
