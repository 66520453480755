.rates-create-form-container {
  width: 100%;
  padding: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .rates-create-title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1%;
    .rates-create-screen-title {
      text-align: center;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
    }
  }
  .rates-create-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
