.bills-dashboard-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .bills-dashboard-screen-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5%;
  }
  .bills-dashboard-screen-title {
    text-align: center;
    padding-bottom: 1%;
    font-size: 2.05em;
    letter-spacing: 0.03em;
    text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
  }
  .bills-dashboard-form-container {
    border: 1px solid #ffc107;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(239, 239, 239, 0.7);
    border-radius: 20px;
    padding: 2% 0.5%;
    margin-bottom: 2%;
  }
  .bills-dashboard-grid-container {
    margin: 0px;
    width: 100%;
    .bills-dashboard-grid-item {
      padding: 1%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover {
        transform: scale(1.02);
      }
      .bills-dashboard-data-container {
        padding: 0px;
        width: 100%;
        background-color: rgb(239, 239, 239, 0.7);
      }
    }
  }
}
