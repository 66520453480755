.lh-main-container {
  width: 100%;
  .grid-logo-container {
    /* border-radius: 25px; */
    background-size: cover;
    background-position: center;
    background: #001219;
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(#595959),
      to(#ffffff)
    );
    background: linear-gradient(to bottom, #4e4e4e, #ffffff);
    width: 100%;
    margin: 0%;
    display: flex;
    justify-content: center;
    .logo {
      /* padding: 0px;
      margin: 1% 0%; */
      display: flex;
      justify-content: center;
      .newLogo {
        max-width: 270px;
        max-height: 210px;
      }
    }
  }
}
