.login-form-main-container {
  width: 100%;
  .login-title-container {
    width: 100%;
    margin-top: 1%;
    padding: 0px;
    .login-page-title {
      letter-spacing: 0.16rem;
      margin: 0px;
      font-size: 2em;
    }
  }
  .login-inputs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .login-grid-container {
      padding: 2% 0%;
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      .login-inputs-grid {
        width: 75%;
        border-radius: 15px;
        margin: 0%;
        display: flex;
        justify-content: center;
        .login-grid-item {
          margin: 2%;
          width: 85%;
        }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    .login-btns-container {
      margin-top: 1.5%;
      .buttons-stack {
        justify-content: center;
      }
      .forgotten-password-container {
        margin-top: 10%;
        .forgotten-password-link {
          font-size: 0.855em;
          letter-spacing: 0.02em;
          text-decoration: underline;
          color: black;
        }
      }
    }
  }
}
