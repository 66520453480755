.barbershops-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .barbershops-grid-container {
    padding-top: 0px;
    width: 95%;
    display: flex;
    justify-content: center;
    .grid {
      border: 2px solid lightgray;
      border-radius: 15px;
      padding-top: 2%;
      margin: 0%;
      display: flex;
      justify-content: center;
      .inner-grid {
        width: 95%;
        margin: 0px;
        padding: 0px;
        .inner-grid-inputs-container {
          width: 100%;
          padding: 0px;
          margin: 0px;
          .datePicker-container {
            display: flex;
            justify-content: center;
          }
          .grid-item-small {
            padding-top: 2%;
            margin-bottom: 0.5%;
            align-items: center;
          }
        }
      }
      .text-inputs-main-container {
        width: 95%;
        .grid-item {
          margin: 2%;
          width: 95%;
          padding-top: 2%;
        }
      }
      .grid-buttons-container {
        padding: 0%;
        margin-top: 2%;
        .buttons-stack {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.back-btn-container {
  margin: 2%;
  display: flex;
  justify-content: center;
}
