.customers-screen-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .customers-screen-title-container {
    width: 75%;
    display: flex;
    justify-content: center;
    margin-bottom: 2.5%;
    .customers-screen-title {
      width: 95%;
      display: flex;
      margin-bottom: 1%;
      justify-content: center;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
      text-align: center;
    }
  }
  .customers-home-grid-container {
    margin: 0px;
    width: 100%;
    .customers-home-grid-item {
      padding: 1%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover {
        transform: scale(1.02);
      }
      .customers-home-data-container {
        padding: 0px;
        width: 100%;
        background-color: rgb(239, 239, 239, 0.355);
      }
    }
  }
  .customers-home-back-button-container {
    margin-top: 3%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
