.sellersEdit-form-background {
  width: 100%;
  padding: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .sellersEdit-form-title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1%;
    .sellersEdit-form-title {
      text-align: center;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
    }
  }
  .sellersEdit-customForm-container {
    width: 100%;
    margin-top: 4%;
    display: flex;
    justify-content: center;
  }
}
