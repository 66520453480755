.rates-form-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .rates-form-grid-container {
    padding-top: 0px;
    width: 95%;
    display: flex;
    justify-content: center;
    .rates-form-grid {
      border: 2px solid lightgray;
      border-radius: 15px;
      padding-top: 2%;
      margin: 0%;
      display: flex;
      justify-content: center;
      .rates-inner-grid {
        width: 95%;
        margin: 0px;
        padding: 0px;
        .rates-inner-grid-item {
          padding: 0px;
          margin: 0px;
          .inner-grid-select-container {
            width: 100%;
            padding: 0px;
            margin: 0px;
          }
          .mini-select-container {
            padding: 1.5%;
            .select-container {
              width: 100%;
              padding: 0px;
              margin: 0px;
            }
          }
        }
      }
      .rates-form-inputs-container {
        width: 95%;
        .grid-item {
          margin: 2%;
          width: 95%;
          padding-top: 2%;
        }
      }
      .rates-buttons-container {
        padding: 0%;
        margin-top: 2%;
        .rates-buttons-stack {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .rates-back-button-container {
          margin: 2%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
