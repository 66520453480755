.owners-form-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .owners-grid-container {
    padding-top: 0px;
    width: 95%;
    display: flex;
    justify-content: center;
    .owners-grid-form {
      border: 2px solid lightgray;
      border-radius: 15px;
      padding-top: 2%;
      margin: 0%;
      display: flex;
      justify-content: center;
      .owners-form-inputs-container {
        width: 95%;
        .grid-item {
          margin: 2%;
          width: 95%;
          padding-top: 2%;
          .date-container {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    .owners-form-buttons-container {
      padding: 0%;
      margin-top: 2%;
      .owners-form-buttons-stack {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .onwers-form-back-button-container {
        margin: 2%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.form-background {
  background-color: red;
  padding: 3%;
}

.date-container {
  width: 100%;
}

//  ---------- BOTON REINICIAR ----------
.noselect {
  width: 150px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: red;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background: #e62222;
}

.noselect,
.noselect span {
  transition: 200ms;
}

.noselect .text {
  transform: translateX(35px);
  color: white;
  font-weight: bold;
}

.noselect .icon {
  position: absolute;
  border-left: 1px solid #c41b1b;
  transform: translateX(110px);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noselect svg {
  width: 15px;
  fill: #eee;
}

.noselect:hover {
  background: #ff3636;
}

.noselect:hover .text {
  color: transparent;
}

.noselect:hover .icon {
  width: 150px;
  border-left: none;
  transform: translateX(0);
}

.noselect:focus {
  outline: none;
}

.noselect:active .icon svg {
  transform: scale(0.8);
}

//  ---------- BOTON CREAR ----------

.create {
  border: 2px solid #24b4fb;
  background-color: #24b4fb;
  width: 5cm;
  border-radius: 0.9em;
  padding: 0.8em 1.2em 0.8em 1em;
  transition: all ease-in-out 0.7s;
  font-size: 16px;
}

.create span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.create:hover {
  background-color: #0071e2;
}
