.containerLogged {
  background-color: white;
  height: 100%;
}

.unstyled {
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
}

.children-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-content-container {
  width: 100%;
}

.drawer-content-container .main-box {
  margin: 0px;
  padding-top: 1%;
  /* padding-left: 10%;
  padding-right: 10%; 
  */
}

//TODO: hay que revisar ese estilo!
