.sellers-detail-main-screen {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .sellers-detail-screen-title-container {
    width: 85%;
    display: flex;
    justify-content: center;
    margin-bottom: 2.5%;
    .sellers-detail-screen-title {
      width: 75%;
      display: flex;
      align-self: center;
      justify-content: center;
      text-align: center;
      > div {
        width: 100%;
        background-color: rgba(31, 32, 45, 0.885);
        border-radius: 15px;
        color: #ffc107;
        padding: 3%;
        font-size: 2.55rem;
        letter-spacing: 0.0000833em;
        font-weight: 400;
      }
    }
  }
  .sellers-detail-grid-container {
    margin: 0px;
    width: 100%;
    .sellers-detail-grid-item {
      padding: 1%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover {
        transform: scale(1.02);
      }
      .sellers-detail-data-container {
        padding: 0px;
        width: 100%;
        background-color: rgb(240, 228, 130);
      }
    }
  }
  .sellers-detail-back-button-container {
    margin-top: 3%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
