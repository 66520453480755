.rates-edit-screen {
  width: 90%;
  display: flex;
  justify-content: center;
  .edit-form-container {
    width: 100%;
    padding: 3%;
    justify-content: center;
    align-items: center;
    .edit-form-title-container {
      text-align: center;
      margin-bottom: 2%;
      .edit-form-title {
        font-size: 1.15em;
        letter-spacing: 0.035rem;
      }
    }
    .edit-customForm-container {
      width: 100%;
      margin-top: 3%;
      display: flex;
      justify-content: center;
    }
  }
}
