.barbershops-detail-page {
  width: 100%;
  .bsDetail-screen-container {
    display: flex;
    justify-content: center;
    .bsDetail-title-container {
      width: 75%;
      display: flex;
      align-self: center;
      justify-content: center;
      text-align: center;
      > div {
        width: 100%;
        background-color: rgba(31, 32, 45, 0.885);
        border-radius: 15px;
        color: #ffc107;
        padding: 3%;
        font-size: 2.55rem;
        letter-spacing: 0.0000833em;
        font-weight: 400;
      }
    }
  }
  .bsDetail-grid-container {
    width: 100%;
    margin: 0px;
    .bsDetail-grid-item {
      padding: 1%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover {
        transform: scale(1.02);
      }
      .bsDetail-data-container {
        /* padding: 0px;
        width: 100%;
        background-color: rgba(235, 173, 7, 0.5); */
        padding: 0px;
        width: 100%;
        background-color: rgb(240, 228, 130);
      }
      .accordion-panels {
        background-color: rgba(235, 173, 7, 0.1);
        .barbershops-attributes {
          .attribute {
            text-align: start;
            font-size: 1.2rem;
          }
          .attribute-description {
            font-size: 1.15rem;
          }
        }
        .accordion-data-container {
          .name-list-container {
            text-align: start;
            margin-bottom: 0.5;
          }
        }
      }
    }
  }
}

.bsDetail-back-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}
