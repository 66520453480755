.barbers-form-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .barbers-form-grid-container {
    padding-top: 0px;
    width: 95%;
    display: flex;
    justify-content: center;
    .barbers-form-grid {
      border: 2px solid lightgray;
      border-radius: 15px;
      padding-top: 2%;
      margin: 0%;
      display: flex;
      justify-content: center;
      .barbers-inner-grid {
        width: 97%;
        margin: 0px;
        padding: 0px;
        .grid-item-xs {
          padding-top: 2%;
          margin-bottom: 0.5%;
          align-items: center;
          .barbers-inner-grid-inputs {
            width: 100%;
            padding: 0px;
            margin: 0px;
            .date-container {
              display: flex;
              padding: 2.25%;
              justify-content: center;
            }
          }
        }
      }
      .barbers-grid-item-container {
        width: 95%;
        .barbers-grid-item {
          margin: 2%;
          width: 95%;
          padding-top: 2%;
        }
      }
      .barbers-form-buttons-container {
        padding: 0%;
        margin-top: 2%;
        .barbers-form-buttons-stack {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .barbers-form-back-button-container {
          margin: 2%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
