.rates-home-screen {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .rates-header {
    display: flex;
    justify-content: center;
    align-items: center;
    .rates-header-container {
      margin: 1%;
      width: 100%;
      .rates-header-item {
        padding: 0px;
        .rates-home-title {
          text-align: flex-start;
          font-size: 2.05em;
          letter-spacing: 0.03em;
          margin-left: 1%;
          text-shadow: -2px 3px 3px rgba(0, 0, 0, 0.261);
        }
      }
      .rates-header-item-button {
        padding: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .day-accordion-container {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    width: 100%;
    .parent-accordion-panel {
      &:hover {
        transform: scale(1.015);
      }
      background-color: rgba(255, 211, 78, 0.775);
      border-radius: 5px;
      .parent-accordion-titles {
        font-size: 1.15em;
        font-weight: 400;
        letter-spacing: 0.115em;
        .parent-accordion-attribute-container {
          align-self: center;
          width: 80%;
          margin-left: 2%;
        }
      }
    }
    .schedule-accordion-container {
      margin: 1% auto;
      width: 90%;
      .child-accordion-panel {
        &:hover {
          transform: scale(1.015);
        }
        background-color: rgb(255, 231, 158);
        border-radius: 5px;
        .child-accordion-titles {
          &:focus {
            font-weight: bold;
            font-size: 1.05em;
          }
          font-size: 1.02em;
          font-weight: 400;
          letter-spacing: 0.05em;
        }
      }
      .accordion-table-container {
        width: 100%;
        border: 2px solid red;
      }
    }
  }
}
