.rates-detail-page {
  width: 100%;
  .rates-detail-title-container {
    display: flex;
    justify-content: center;
    .rates-detail-title {
      width: 75%;
      display: flex;
      align-self: center;
      justify-content: center;
      text-align: center;
      > div {
        /* background-color: rgb(45, 45, 45); */
        background-color: rgba(31, 32, 45, 0.885);
        border-radius: 15px;
        color: #ffc107;
        padding: 3%;
        font-size: 2.55rem;
        letter-spacing: 0.0000833em;
        font-weight: 400;
        /* text-shadow: -3px 2px 1px rgba(255, 255, 255, 0.261); */
      }
    }
  }
  .rates-detail-grid-container {
    width: 100%;
    margin: 0px;
    .rates-detail-grid-item {
      padding: 1%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      .rates-detail-data-container {
        padding: 0px;
        width: 100%;
        background-color: rgb(240, 228, 130);
        &:hover {
          transform: scale(1.01);
        }
      }
    }
  }
  .rates-detail-back-button-container {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
  }
}
